import React from 'react'
import styled from 'styled-components'
import { down, up } from 'styled-breakpoints'
//DATA
import { info } from '../assets/data/kontakt'
//STYLES
import { H1, H4, Text, LabelBW } from '../utils/typo'
import { Logo, Section, ContainerMob } from '../utils/utils'
//COMPONENTS
import SEO from '../components/seo/SEO'
import MapContainer from '../components/googleMap/GoogleMap'
import Layout from '../layout/layoutBW'

const Baner = styled.div`
    margin-top: 1rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 7rem;
    

    ${down('sm')} {
    grid-template-columns: 1fr;
    grid-row-gap: 1rem;
    }

    p {
        white-space: pre-wrap;
    }

    iframe {
      filter: grayscale(100%);
      ${down('sm')} {
      display: none;
      }
    }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const MapMobile = styled.div`
  margin-top: 4rem;
  > div {
    height: 300px;
  }

  iframe {
      filter: grayscale(100%);
      height: 300px;
    }

  ${up('md')} {
    display: none;
    }
`;

const Kontakt = () => {

  return (
    <div>
      <SEO
        title='Kontakt'
        slug='/kontakt' />
      <Layout>
        <Logo />
        <Section
          data-sal="fade"
          data-sal-delay='100'
          data-sal-duration='600'>

          <ContainerMob>
            <Baner>
              {/* <MapContainer /> */}
              <iframe
                width="100%"
                height="100%"
                frameborder="0"
                scrolling="no"
                marginheight="0"
                marginwidth="0"
                src="https://www.openstreetmap.org/export/embed.html?bbox=18.672959804534916%2C50.28877715875011%2C18.681918382644657%2C50.29189601418448&amp;layer=mapnik&amp;marker=50.29033661202005%2C18.677439093589783"
              />

              <TextWrapper>
                <LabelBW mb='1rem'>Kontakt</LabelBW>
                <H1>Zostańmy w kontakcie!</H1>
                <Text>
                  — Zapraszamy do kontaktu o każdej porze
                </Text>
                <H4 mt='2rem'>+48 {info.tel}</H4>
                <H4>{info.mail}</H4>

                <LabelBW mb='1rem' mt='3rem'>Adres</LabelBW>
                <p>ul. {info.ulica} {info.nrBud}</p>
                <p>{info.kod} {info.miasto}</p>
                <p>{info.woj}</p>
                <p>{info.kraj}</p>

                <LabelBW mb='1rem' mt='3rem'>Firma</LabelBW>
                <p><b>NIP: </b>{info.nip}</p>
                <p><b>REGON: </b>{info.regon}</p>
              </TextWrapper>
            </Baner>

            <MapMobile>
              {/* <MapContainer /> */}
              <iframe
                width="100%"
                height="100%"
                frameborder="0"
                scrolling="no"
                marginheight="0"
                marginwidth="0"
                src="https://www.openstreetmap.org/export/embed.html?bbox=18.672959804534916%2C50.28877715875011%2C18.681918382644657%2C50.29189601418448&amp;layer=mapnik&amp;marker=50.29033661202005%2C18.677439093589783"
              />
            </MapMobile>

          </ContainerMob>
        </Section>
      </Layout>
    </div >
  )
}
export default Kontakt